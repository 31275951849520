.add-wrap {
  margin: 10px 0 10px;
  display: flex;
  justify-content: flex-end;
}

.corp-table {
  margin-top: 10px;
}

.caret-wrap {
  display: flex;
  flex-direction: column;
  width: 20px;

  >i {
    font-size: 16px;
    cursor: pointer;
  }
}

.filter-wrap {
  display: flex;
  margin-top: 20px;
  align-items: center;

  .title {
    margin-bottom: 0;
    font-weight: 400;
  }

  .filter-item {
    display: flex;
    align-items: center;
    margin-right: 15px;

    > label {
      width: 70px;
      // text-align: right;
      margin-right: 5px;
    }
  }

  .btn-group {
    margin-left: 40px;
  }
}

.select-input {
  .ant-form-item-children {
    display: flex;
    flex-wrap: wrap;
    .ant-select {
      width: 80px;
      margin-right: 10px;
    }
    .ant-input-number {
      width: 314px;
    }
    .amount-tag {
      width: 100%;
      padding-left: 92px;
      font-size: 12px;
      color: #B2B2B2;
    }
  }
}

.tag-disable {
  background: #ccc!important;
  color: #666!important;
  cursor: no-drop!important;
  &:hover {
    opacity: 1!important;
  }
}
.corp-filter-wrap-set{
  .corp-filter-wrap-set-item{
    .ant-tag-checkable{
      padding: 5px;
      border: #fff 1px solid;
      cursor: pointer;
      &:hover{
        color: #333
      }
    }
    .ant-tag-checkable-checked{
      border: #1890ff 1px solid;
      color: #1890ff;
      background-color: #fff;
    }
  }
}

// 设置主项抽屉
.projectsListSty {
  .ant-tag {
    margin-bottom: 5px;
  }
}